import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Checkbox from "../../components/checkbox"
import Constrain from "../../components/constrain"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Layout from "../../layouts/default"
import NoBreak from "../../components/no-break"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import SectionSpacing from "../../components/section-spacing"
import Stack from "../../components/stack"
import Text from "../../components/text"
import TextLink from "../../components/textlink"
import Trivia from "../../components/trivia"
import VideoPlayer from "../../components/video-player"
import Columns from "../../components/columns"
import Column from "../../components/column"

const WorkshopFlussPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopFlussPageQuery {
      superHoelderlin: file(
        relativePath: { eq: "workshop-fluss/super-hoelderlin.jpg" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(width: 100, height: 100, layout: FIXED)
        }
      }
      cover: file(relativePath: { eq: "workshop-fluss/cover.jpg" }) {
        ...largeImage
      }
      poster01: file(
        relativePath: { eq: "workshop-fluss/poster-fluss-01.jpg" }
      ) {
        ...largeImage
      }
      poster02: file(
        relativePath: { eq: "workshop-fluss/poster-fluss-02.jpg" }
      ) {
        ...largeImage
      }
      poster03: file(
        relativePath: { eq: "workshop-fluss/poster-fluss-03.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Layout backdrop="workshop-fluss">
      <Seo
        title="Wohin der Fluss mich treibt"
        description="Kann man auf Reisen gehen, ganz ohne in ein Flugzeug, in ein Auto oder in den Zug zu steigen? In drei Video-Tutorials für Kinder und Jugendliche von 10-14 Jahren zeigen wir, wie es geht und was das mit Hölderlin zu tun hat."
      />
      <SectionSpacing>
        <Stack>
          <Stack>
            <Stack>
              <Breadcrumb
                items={[
                  {
                    title: "Digital",
                    link: "/digital",
                  },
                  {
                    title: "Workshops",
                    link: "/workshops",
                  },
                  {
                    title: "Wohin der Fluss mich treibt",
                    link: "/workshops/wohin-der-fluss-mich-treibt",
                  },
                ]}
              />
              <PageTitle>Wohin der Fluss mich treibt</PageTitle>
              <Heading as="h2" level={5} fontFamily="serif" bold={false}>
                Ein Workshop für Kinder und Jugendliche von 10-14 Jahren
              </Heading>
            </Stack>
            <Paragraph dropcap={true}>
              Kann man auf Reisen gehen, ganz ohne in ein Flugzeug, in ein Auto
              oder in den Zug zu steigen? Der Dichter Friedrich Hölderlin konnte
              das. In seinen Gedichten ist er vom Neckar bis in sein
              Lieblingsland Griechenland gereist. In diesen Video-Tutorials
              erzählen wir euch, wie das geht und wie ihr euch damit die
              Wochenend- und Ferientage vertreiben könnt.
            </Paragraph>
          </Stack>
          <Image
            image={data.cover.childImageSharp.gatsbyImageData}
            alt="Selbstgebasteltes Leporello mit Flussverlauf"
          />
        </Stack>

        <Constrain>
          <Stack>
            <Stack space={3}>
              <Heading as="strong" color="whipser">
                Schritt 1
              </Heading>
              <Heading as="h2" level={2}>
                Die Idee: In Gedanken auf Reisen gehen
              </Heading>
            </Stack>
            <Paragraph>
              Obwohl Hölderlin sein Zuhause im Tübinger Turm die letzten 36
              Jahre seines Lebens kaum mehr verlassen hat, war ihm eigentlich
              nie langweilig. Immerhin hatte sein Zimmer 5 Fenster, aus denen er
              auf den Neckar schauen konnte: Einen Fluss, der ihm schon von
              klein auf vertraut war und mit dem es sich prima auf Reisen gehen
              ließ. Wie das geht und wie ihr ihm das nachmachen könnt, erfahrt
              ihr im ersten Clip.
            </Paragraph>
            <VideoPlayer src="fluss-01" poster={data.poster01} />
          </Stack>
        </Constrain>

        <Constrain>
          <Stack>
            <Stack space={3}>
              <Heading as="strong" color="whipser">
                Schritt 2
              </Heading>
              <Heading as="h2" level={2}>
                Ein eigenes Buch basteln – so geht das...
              </Heading>
            </Stack>
            <Paragraph>
              Man muss kein großer Schriftsteller sein, um sein eigenes Buch im
              Regal stehen zu haben. Ein Buch könnt ihr euch auch ganz leicht
              selber basteln.
            </Paragraph>
            <VideoPlayer src="fluss-02" poster={data.poster02} />
            <Stack space={6}>
              <Paragraph>Ihr braucht dafür:</Paragraph>
              <Stack space={4}>
                <Checkbox space={3} id="buch-basteln-papier">
                  <Text as="span" size={4}>
                    Ein großes A3-Papier (wenn ihr mit Wasserfarben oder
                    Aquarell-Farben zeichnen wollt: Aquarell-Papier)
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="buch-basteln-schere">
                  <Text as="span" size={4}>
                    Eine Schere
                  </Text>
                </Checkbox>
              </Stack>
            </Stack>
          </Stack>
        </Constrain>

        <Constrain>
          <Stack>
            <Stack space={3}>
              <Heading as="strong" color="whipser">
                Schritt 3
              </Heading>
              <Heading as="h2" level={2}>
                Maltechniken und Inspirationen für eure Geschichten
              </Heading>
            </Stack>
            <Paragraph>
              Wenn ihr euer Buch fertig gebastelt habt, geht es ans Malen und
              Geschichten-Erfinden. In diesem Video stellen wir euch
              verschiedene Ideen vor.
            </Paragraph>
            <VideoPlayer src="fluss-03" poster={data.poster03} />
            <Stack space={6}>
              <Stack space={2}>
                <Heading as="h3" level={5}>
                  1. Buntstifte
                </Heading>
                <Paragraph>Ihr braucht nichts mehr ...</Paragraph>
              </Stack>
              <Stack space={4}>
                <Checkbox space={3} id="buntstifte">
                  <Text as="span" size={4}>
                    als ein paar Buntstifte
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="bleistift">
                  <Text as="span" size={4}>
                    einen Bleistift zum Vorzeichnen
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="filzstifte">
                  <Text as="span" size={4}>
                    wenn ihr mögt auch Filzstifte
                  </Text>
                </Checkbox>
              </Stack>
            </Stack>

            <Stack space={6}>
              <Stack space={2}>
                <Heading as="h3" level={5}>
                  2. Wachsmalkreide
                </Heading>
                <Paragraph>Ihr braucht ...</Paragraph>
              </Stack>
              <Stack space={4}>
                <Checkbox space={3} id="wachsmalkreiden">
                  <Text as="span" size={4}>
                    Wachsmalkreide
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="schaber">
                  <Text as="span" size={4}>
                    einen Wachsmal-Schaber
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="unterlage">
                  <Text as="span" size={4}>
                    eine Unterlage
                  </Text>
                </Checkbox>
              </Stack>
            </Stack>

            <Stack space={6}>
              <Stack space={2}>
                <Heading as="h3" level={5}>
                  3. Aquarell-Malfarben
                </Heading>
                <Paragraph>Ihr braucht ...</Paragraph>
              </Stack>
              <Stack space={4}>
                <Checkbox space={3} id="aquarell-papier">
                  <Text as="span" size={4}>
                    Aquarell-Papier
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="aquarell-malkreiden">
                  <Text as="span" size={4}>
                    Aquarell-Malkreiden
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="wasser">
                  <Text as="span" size={4}>
                    Wasser
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="pinsel">
                  <Text as="span" size={4}>
                    einen oder mehrere Pinsel
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="kuechenpapier">
                  <Text as="span" size={4}>
                    etwas Küchenpapier
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="aquarell-unterlage">
                  <Text as="span" size={4}>
                    eine Unterlage
                  </Text>
                </Checkbox>
              </Stack>
            </Stack>

            <Stack space={6}>
              <Stack space={2}>
                <Heading as="h3" level={5}>
                  ... Und für ein bewegliches Boot
                </Heading>
              </Stack>
              <Stack space={4}>
                <Checkbox space={3} id="karton">
                  <Text as="span" size={4}>
                    Karton (z.B. von einer Klopapier-Rolle)
                  </Text>
                </Checkbox>
                <Checkbox space={3} id="schere">
                  <Text as="span" size={4}>
                    eine Schere oder einen Cutter
                  </Text>
                </Checkbox>
              </Stack>
            </Stack>
            <Paragraph>
              Wir wünschen euch viel Spaß beim Basteln, Malen und
              Geschichten-Erfinden! Wenn ihr mögt, könnt ihr uns auch ein Foto
              von euren fertigen Büchern und Geschichten an{" "}
              <NoBreak>
                <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                  hoelderlinturm@tuebingen.de
                </TextLink>
              </NoBreak>{" "}
              schicken!
            </Paragraph>
          </Stack>
        </Constrain>

        <Constrain>
          <Stack>
            <Heading as="h2" level={2}>
              Hölderlins Gedicht ›Der Neckar‹ zum Nachhören.{" "}
            </Heading>
            <Paragraph>
              Vom Neckar an den Rhein und weiter bis nach Griechenland zu den
              Tempel-Ruinen, Lorbeer-Wäldern und Weinbergen träumt sich
              Hölderlin mit seinem vertrauten Wander-Freund, dem Neckar. Hier
              könnt ihr das ganze Gedicht nachhören.
            </Paragraph>
            <AudioPlayer src={`workshop-fluss/der-neckar`} />
            <Trivia title="Übrigens ..." size={3}>
              <Columns>
                <Column>
                  Wie Hölderlin in seinem Gedicht ›Der Neckar‹ mit Rhythmus und
                  Betonungen spielt, könnt ihr in unserem Sprachlabor im
                  Hölderlinturm in einem Jump-and-run-Spiel selbst erkunden.
                </Column>
                <Column width="content">
                  <Image
                    image={data.superHoelderlin.childImageSharp.gatsbyImageData}
                    alt="Screenshot des Jump 'n' Run Spiels aus dem Sprachlabor"
                  />
                </Column>
              </Columns>
            </Trivia>
          </Stack>
        </Constrain>

        <Constrain>
          <Stack alignX="center" space={2}>
            <Heading as="strong">Vielen Dank an</Heading>
            <Text>Peter Bötsch für den Film und Hanna Hocker für den Ton!</Text>
          </Stack>
        </Constrain>
      </SectionSpacing>
    </Layout>
  )
}

export default WorkshopFlussPage
